import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Form as BaseForm } from 'mobx-react-form';

import container from '@Clinic/core/di-container';
import { LayoutView } from '@Clinic/core/Layout/Layout.constants';
import { getLayoutView } from '@Clinic/core/Layout/Layout.utils';
import LayoutStore, { LayoutConfig } from '@Clinic/shared/stores/layout';
import DentistsStore from '@Clinic/shared/stores/dentists';
import AuthStore from '@Clinic/shared/stores/auth';
import ROUTES from '@Clinic/shared/constants/routes';
import { PatientDTO } from '@Clinic/shared/models/patient';
import { DentistDTO } from '@Clinic/shared/models/dentist';
import { NotificationType, showNotification } from '@shared/components/Notification';
import Message from '@shared/components/Message';
import Button, { ButtonProps } from '@shared/components/Button';
import { showErrors } from '@shared/utils/form';
import { getDefaultError } from '@shared/utils/common';
import history from '@shared/utils/history';
import EnvelopeIcon from '@shared/icons/Envelope';
import DentistSelect, { DentistSelectProps } from '../../../../shared/components/DentistSelect';
import PersonalDetails, { PersonalDetailsProps } from './components/PersonalDetails';
import PersonalDetailsForm from './forms/personal-details';

import styles from './SignUp.styles';

export interface SignUpProps extends WithStyles<typeof styles>, RouteComponentProps, WithWidth {}

enum Step {
  personalDetails = 1,
  dentistSelect = 2,
}

interface StepConfig {
  navigation: LayoutConfig['navigationProps'];
  component: React.ComponentType<any>;
  componentProps?: Partial<PersonalDetailsProps> | Partial<DentistSelectProps>;
  stepperButton?: Partial<ButtonProps>;
}

const FIRST_STEP = Step.personalDetails;

@observer
class SignUp extends React.Component<SignUpProps> {
  private authStore = container.get<AuthStore>(AuthStore.diToken);
  private layoutStore = container.get<LayoutStore>(LayoutStore.diToken);
  private dentistStore = container.get<DentistsStore>(DentistsStore.diToken);
  private form: BaseForm = new PersonalDetailsForm();
  @observable private isRegistrationCompleted = false;
  @observable private step = FIRST_STEP;
  @observable private dentistSelectedId: PatientDTO['dentistId'] | undefined;
  @observable private submitting = false;
  private topRef = React.createRef<HTMLDivElement>()

  constructor(props: SignUpProps) {
    super(props);
    
    this.setContentWidth();
    this.initialize();
  }

  initialize = () => {
    this.setLayoutConfig();
    this.dentistStore.getList();
  };

  private setLayoutConfig = () => {
    const { navigation } = this.stepperConfig;

    this.layoutStore.updateConfig({
      navigationProps: {
        backButtonProps: {
          onClick: navigation?.backButtonProps?.onClick,
        },
        pageDescription: 'Sign Up',
      },
    });
  };

  componentDidUpdate(prevProps: SignUpProps) {
    if (this.props.width !== prevProps.width) {
      this.setContentWidth();
    }
  }

  componentWillUnmount() {
    this.layoutStore.setDefaultConfig();
  }

  private setContentWidth = () => {
    this.layoutStore.updateConfig({
      content: {
        width: getLayoutView(this.props.width) === LayoutView.desktop ? 525 : '100%',
      },
    });
  };

  private handleSubmit = async () => {
    this.submitting = true;

    try {
      const data: Partial<PatientDTO> = this.form.values();

      data.dentistId = this.dentistSelectedId;

      await this.authStore.signUp(data);

      this.layoutStore.setDefaultConfig();
      this.isRegistrationCompleted = true;
    } catch (err) {
      this.handleSignUpError(err);
    } finally {
      this.submitting = false;
    }
  };

  private handleSignUpError = (err) => {
    const errors = err?.response?.data?.errors || {};

    if ([400, 403].includes(err?.response?.status)) {
      const errorFields = Object.keys(errors);
      const formFields = [...this.form.fields.keys()];
      const formHasError = formFields.some((field) => errorFields.includes(field));

      if (formHasError) {
        this.setStep(Step.personalDetails);
      }

      showErrors(errors, this.form);

      return;
    }

    showNotification(getDefaultError(errors), NotificationType.error);
  };

  private get nextStep() {
    const config = {
      [Step.personalDetails]: Step.dentistSelect,
    };

    return config[this.step];
  }

  private get prevStep() {
    const config = {
      [Step.dentistSelect]: Step.personalDetails,
    };

    return config[this.step];
  }

  private handlePersonalDetailsSubmit = () => {
    this.setStep(this.nextStep);
    this.topRef.current?.scrollIntoView()
    this.setLayoutConfig();
  };

  private setStep = (step: Step) => {
    this.step = step || FIRST_STEP;
  };

  private handleBackButtonDentistsSelect = () => {
    this.setStep(this.prevStep);
    this.setLayoutConfig();
  };

  private handleBackButtonPersonalDetails = () => {
    history.push(ROUTES.public.login);
  };

  private handleSelectedDentistChange = (id?: DentistDTO['id']) => {
    this.dentistSelectedId = id;
  };

  @computed private get stepperConfig() {
    const { classes } = this.props;

    const config: { [key in Step]: StepConfig } = {
      [Step.personalDetails]: {
        navigation: {
          backButtonProps: {
            onClick: this.handleBackButtonPersonalDetails,
          },
        },
        component: PersonalDetails,
        componentProps: {
          form: this.form,
          classes: {
            submitBtn: classes.signUpBtn,
          },
          onSubmit: this.handlePersonalDetailsSubmit,
        },
      },
      [Step.dentistSelect]: {
        navigation: {
          backButtonProps: {
            onClick: this.handleBackButtonDentistsSelect,
          },
        },
        component: DentistSelect,
        componentProps: {
          classes: { root: classes.dentistSelect },
          dentists: this.dentistStore.list,
          selectedDentistId: this.dentistSelectedId,
          onChange: this.handleSelectedDentistChange,
        },
        stepperButton: {
          classes: { root: classes.dentistSelectBtn },
          text: 'Sign up',
          loading: this.submitting,
          onClick: this.handleSubmit,
        },
      },
    };

    return config[this.step];
  }
  
  render() {
    const { classes } = this.props;
    const { component: StepComponent, componentProps, stepperButton } = this.stepperConfig;

    if (this.isRegistrationCompleted) {
      return (
        <Message
          icon={<EnvelopeIcon />}
          heading="Check your email to activate your account"
          subheading="(make sure to check your spam folder!)"
          classes={{
            root: classes.messageRoot,
            heading: classes.messageHeading,
            subheading: classes.messageSubheading,
            button: classes.messageButton,
          }}
          buttonProps={{
            text: 'Go to login page',
            component: Link,
            componentProps: {
              to: ROUTES.public.login,
            },
          }}
        />
      );
    }

    return (
      <div className={classes.root} ref={this.topRef}>
        <StepComponent {...componentProps} />
        {stepperButton && <Button fullWidth className={classes.signUpBtn} {...stepperButton} />}
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(SignUp));
